import React from "react"
import Card from "../../../components/card"

type SectionProps = {
  id: string
  header: string
  description?: string
  children: React.ReactNode
}

export function Section(props: SectionProps) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/forms/form-layouts#component-b5675d2efaa71ec3767969fd53fc3b66
  return (
    <div
      id={props.id}
      className="md:grid md:grid-cols-4 md:gap-6 border-b pb-8 mb-8 border-gray-100"
    >
      <div className="md:col-span-1 text-center md:text-left">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {props.header}
        </h3>
        {(props.description ?? "").split("\n\n").map(description => (
          <p key={description} className="mt-1 text-sm text-gray-500">
            {description}
          </p>
        ))}
      </div>
      <Card className="col-span-3 w-full md:my-0">{props.children}</Card>
    </div>
  )
}

export default Section
